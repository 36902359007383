import styled from 'styled-components'

export const Sec = styled.section`
  background-color: ${props => props.bg || 'inherit'};
  padding: ${props => props.space || '40px 0px'};
  font-family: 'Roboto', sans-serif;
`
export const Wrap = styled.div`
  width: 85%;
  max-width: ${props => props.width || '1030px'};
  margin: 0 auto;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.cols || '1fr'};
  grid-gap: ${props => props.gap || '3vmax'};

  @media screen and (max-width: ${props => props.bp || '980px'}) {
    grid-template-columns: 1fr;
  }
`

export const Col = styled.div`
  background-color: ${props => props.bg || 'inherit'};
  padding: ${props => props.space || '0px'};
  display: ${props => (props.flex ? 'flex' : 'initial')};
  flex-direction: ${props => (props.col ? 'column' : 'row')};
  justify-content: ${props => props.justify || 'none'};
  align-items: ${props => props.align || 'none'};
`
