import { Link } from 'gatsby'
import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Logo from '../images/biobrudarna-logo-white-400.png'

import { Sec, Wrap, Col } from '../components/styled/layout'

const NavLink = styled.div`
  padding: 10px 20px;
  color: #ffffff60;
  .active & {
    color: white;
  }
`

const Header = () => (
  <nav>
    <Sec space="15px 0px" bg="#441E1E">
      <Wrap>
        <Col flex align="center" justify="">
          <Link style={{ marginRight: '20px' }} to="/">
            <img
              style={{ height: '80px', marginBottom: '0px' }}
              src={Logo}
              alt="Biobrudarna logotyp"
            />
          </Link>
          <Link activeClassName="active" to="/">
            <NavLink>Filmer</NavLink>
          </Link>
          <Link activeClassName="active" to="/info">
            <NavLink>Info</NavLink>
          </Link>
        </Col>
      </Wrap>
    </Sec>
  </nav>
)

export default Header
